import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Sidebar from '../components/sidebar';
import { Container, Row, Col, Button, Table } from 'react-bootstrap';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import CalendarHeatmap from 'react-calendar-heatmap';
import 'react-calendar-heatmap/dist/styles.css';

const mapContainerStyle = {
  width: '100%',
  height: '100%',
};

const center = {
  lat: 53.2707,
  lng: -9.0568,
};

const today = new Date();

function shiftDate(date, numDays) {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + numDays);
  return newDate;
}

function getRange(count) {
  return Array.from({ length: count }, (_, i) => i);
}

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const randomValues = getRange(200).map((index) => ({
  date: shiftDate(today, -index),
  count: getRandomInt(1, 3),
}));

function ViewVehicle() {
  const navigate = useNavigate();
  const { id } = useParams(); // Get vehicle ID from the URL

  const token = localStorage.getItem('token'); // Retrieve the token
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyBaFbI9gXbbJ334P10IRIenzDvBlyVvoqE',
  });

  const [vehicle, setVehicle] = useState(null); // Vehicle data state
  const [loading, setLoading] = useState(true); // Loading state

  // Fetch vehicle data
  useEffect(() => {
    const fetchVehicle = async () => {
      if (!token) {
        navigate('/login');
        return;
      }

      try {
        const response = await fetch(`https://backseatdriver-ie-api.onrender.com/vehicles/id/${id}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setVehicle(data);
        } else {
          console.error('Failed to fetch vehicle:', response.status);
        }
      } catch (error) {
        console.error('Error fetching vehicle:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchVehicle();
  }, [id, navigate, token]);

  // Conditional rendering for loading and authentication
  if (!token) {
    return <div className="text-center mt-5">Not logged in</div>;
  }

  if (loading) {
    return <p>Loading vehicle data...</p>;
  }

  if (!vehicle) {
    return <p>Vehicle not found</p>;
  }

  return (
    <main className="App">
      <div className="d-flex">
        <Sidebar />
        <Container className="flex-grow-1">
          {/* Three white divs side by side */}
          <Row className="mt-5">
            <Col md={5}>
              <div className="bg-white p-3 shadow-sm" style={{ height: '350px', overflowY: 'auto' }}>
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th>Vehicle</th>
                      <th>Connected Datetime</th>
                      <th>Last Login</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{vehicle.name || 'Car 1'}</td>
                      <td>{vehicle.connectedDatetime || 'N/A'}</td>
                      <td>{vehicle.lastLogin || 'N/A'}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>

            <Col md={7}>
              <div>
                <h4>Use calender heatmap</h4>
                <CalendarHeatmap
                  startDate={shiftDate(today, -150)}
                  endDate={today}
                  values={randomValues}
                  classForValue={(value) => {
                    if (!value) {
                      return 'color-empty';
                    }
                    return `color-github-${value.count}`;
                  }}
                  showWeekdayLabels
                  onClick={(value) => alert(`Clicked on value with count: ${value.count}`)}
                />
              </div>
            </Col>
          </Row>

          {/* Google Maps in the long div */}
          <Row className="mt-3">
            <Col>
              <div className="bg-white p-4 shadow-sm" style={{ height: '400px' }}>
                {isLoaded ? (
                  <GoogleMap mapContainerStyle={mapContainerStyle} zoom={10} center={center}>
                    <Marker position={center} />
                  </GoogleMap>
                ) : (
                  <p>Loading map...</p>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </main>
  );
}

export default ViewVehicle;
