import { Button, Container, Form, Nav, Navbar } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
// import ExampleCarouselImage from '../images/backSeatDriverLogo';

function HomePage() {
  return (
    <Container className="mt-4">
      

      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ac ligula a nisi egestas dictum. Nulla facilisi.
        Donec a nunc metus. Curabitur ut lacinia lacus. Phasellus malesuada lectus non fringilla volutpat. Nam accumsan
        nulla et nisl tempor, at interdum nisi euismod. Morbi feugiat, erat a viverra tincidunt, mauris erat egestas dui,
        in pretium purus libero ac neque. Aliquam erat volutpat.
      </p>
    </Container>
  );
}

export default HomePage;