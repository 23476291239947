import React, { useState } from 'react';
import { Nav, Button } from 'react-bootstrap';
import './sidebar.css'; // Import the CSS file for styling
import { MDBIcon } from 'mdb-react-ui-kit';

import { useNavigate } from 'react-router-dom';


const Sidebar = () => {

  const navigate = useNavigate();
  
  // Check if the token exists in session storage
  const token = localStorage.getItem('token');

  // Handle logout
  const handleLogout = () => {
    localStorage.removeItem('token'); // Remove token from session storage
    navigate('/login'); // Redirect to login page
  };

  const [isHovered, setIsHovered] = useState(false); // Manage hover state

  return (
    <div
      className={`sidebar ${isHovered ? 'expanded' : 'collapsed'}`}
      onMouseEnter={() => setIsHovered(true)} // Expand on hover
      onMouseLeave={() => setIsHovered(false)} // Collapse when not hovered
    >
      <div className="sidebar-header border-bottom">
        <div className="sidebar-brand">CM</div>
      </div>
      <ul className="sidebar-nav">
        {/* Vehicle Profile */}
        <li className="nav-item">
          <Nav.Link href="#">
            <i className="nav-icon cil-speedometer"></i>
            <MDBIcon fas icon="desktop" />
            <small>Vehicle Profile</small>
          </Nav.Link>
        </li>

        {/* Usage & Efficiency */}
        <li className="nav-item">
          <Nav.Link href="#">
            <i className="nav-icon cil-speedometer"></i>
            <MDBIcon fas icon="desktop" />
            <small>Usage & Efficiency</small>
          </Nav.Link>
        </li>

        {/* Safety */}
        <li className="nav-item">
          <Nav.Link href="#">
            <i className="nav-icon cil-speedometer"></i>
            <MDBIcon fas icon="desktop" />
            <small>Safety</small>
          </Nav.Link>
        </li>

        <li className="nav-item">
          <Nav.Link href="#">
            <i className="nav-icon cil-speedometer"></i>
            <MDBIcon fas icon="desktop" />
            <small>BackSeatDriver Wiki</small>
          </Nav.Link>
        </li>
        {/* <li className="nav-item">
          <Nav.Link href="#">
            <i className="nav-icon cil-speedometer"></i> With badge
            <span className="badge bg-primary ms-auto">NEW</span>
          </Nav.Link>
        </li> */}
        <li className="nav-item nav-group show">
          <Nav.Link className="nav-group-toggle" href="#" data-toggle="collapse" data-target="#dropdown1" aria-expanded="true">
            <i className="nav-icon cil-puzzle"></i>
            <MDBIcon fas icon="truck-monster" />
            <small>Usage & Efficiency</small>
          </Nav.Link>
          <ul className="nav-group-items collapse show" id="dropdown1">
            <li className="nav-item">
              <Nav.Link href="#">
                <span className="nav-icon">
                  <span className="nav-icon-bullet"></span>
                </span>
                Nav dropdown item
              </Nav.Link>
            </li>
            <li className="nav-item">
              <Nav.Link href="#">
                <span className="nav-icon">
                  <span className="nav-icon-bullet"></span>
                </span>
                Nav dropdown item
              </Nav.Link>
            </li>
          </ul>
        </li>

        <Button variant="danger" onClick={handleLogout}>
          Logout
        </Button>
        {/* <li className="nav-item mt-auto">
          <Nav.Link href="https://coreui.io">
            <i className="nav-icon cil-cloud-download"></i> Download CoreUI
          </Nav.Link>
        </li> */}
        {/* <li className="nav-item">
          <Nav.Link href="https://coreui.io/pro/">
            <i className="nav-icon cil-layers"></i> Try CoreUI <strong>PRO</strong>
          </Nav.Link>
        </li> */}
      </ul>
    </div>
  );
};

export default Sidebar;
